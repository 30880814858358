



























import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import router from "@/router";
import { IUser } from "@/models/IUser";

@Component
export default class UserComponent extends Vue {
  user: IUser = {
    id: undefined,
    userName: "",
    firstName: "",
    lastName: "",
    password: "",
    organisations: [],
  } as IUser;
  initialised: boolean = false;

  async save() {
    if (this.user.id) {
      adminService.updateUser(this.user)
        .then(x => {
          alert("Uppdaterad!");
        });
    }
    else {
      this.user.id = await adminService.createUser(this.user);

      router.replace("/users/" + this.user.id);
    }
  }

  private async activated() {
    if (this.$route.params.id) {
      this.user = await adminService.getUser(this.$route.params.id);
    }
    // else {
    //   this.user = {
    //     id: undefined,
    //     userName: "",
    //     firstName: "",
    //     lastName: "",
    //     password: "",
    //     organisations: [],
    //   } as IUser;
    // }

    this.initialised = true;
  }
}
